import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "full-integration-components-with-hooks"
    }}>{`Full integration components with hooks`}</h1>
    <p>{`The core mental shift with hooks comes in where your D3 code lives 👉 in the main render.`}</p>
    <p>{`Where we used to spread D3 code around the whole component class, hooks let us do it all in the main render method. Because that's all there is to our component - the main render method.`}</p>
    <p>{`Anything bound to component lifecycle goes into `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`, anything we need every time, goes in the function body. When a lot of logic builds up, extract it to a custom hook.`}</p>
    <p>{`If you're worried about performance or have a large dataset 👉 wrap in `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{`.`}</p>
    <p>{`Let's build a scatterplot step by step. Because scatterplots let us focus on the React + D3 interaction :)`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/react-d3-scatterplot-unsolved-hzmcp",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <ol>
      <li parentName="ol">{`Render a circle for each datapoint`}</li>
      <li parentName="ol">{`Use scales to position circles`}</li>
      <li parentName="ol">{`Add axes`}</li>
      <li parentName="ol">{`Use a render prop to make datapoint rendering more flexible`}</li>
      <li parentName="ol">{`Can you make the scatterplot shrink on click?`}</li>
      <li parentName="ol">{`How about the datapoint expand on mouseover?`}</li>
    </ol>
    <h3 {...{
      "id": "my-final-solution"
    }}>{`My final solution`}</h3>
    <iframe {...{
      "src": "https://codesandbox.io/embed/scatterplot-hooks-y4un7",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h2 {...{
      "id": "usememo-is-your-new-best-dataviz-friend"
    }}>{`useMemo is your new best dataviz friend`}</h2>
    <p>{`My favorite hook for making React and D3 work together is `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{`. It's like
a combination of `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` and `}<inlineCode parentName="p">{`useState`}</inlineCode>{`.`}</p>
    <p>{`Remember how the rest of this course focused on syncing D3's internal state
with React's internal state and complications around large datasets and
speeding up your D3 code to avoid recomputing on every render?`}</p>
    <p>{`All that goes away with `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` – it memoizes values returned from a function
and recomputes them when particular props change. Think of it like a cache.`}</p>
    <p>{`Say you have a D3 linear scale. You want to update its range when your
component's width changes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function MyComponent({ data, width }) {
    const scale = useMemo(() =>
        d3.scaleLinear()
            .domain([0, 1])
            .range([0, width])
    ), [width])

    return <g> ... </g>
}
`}</code></pre>
    <p><inlineCode parentName="p">{`useMemo`}</inlineCode>{` takes a function that returns a value to be memoized. In this case
that's the linear scale.`}</p>
    <p>{`You create the scale same way you always would. Initiate a scale, set the
domain, update the range. No fancypants trickery.`}</p>
    <p><inlineCode parentName="p">{`useMemo`}</inlineCode>{`'s second argument works much like useEffect's does: It tells React
which values to observe for change. When that value changes, `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` reruns
your function and gets a new scale.`}</p>
    <p>{`Don't rely on `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` running only once however. Memoization is meant as a
performance optimization, a hint if you will, not as a syntactical guarantee of
uniqueness.`}</p>
    <p>{`Try adding `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` in your code from before to optimize performance. See what happens if we render thousands of points instead of tens.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      